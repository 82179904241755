<template>
  <div class="v-date-picker">
    <section class="show-element">
      <el-date-picker
        ref="datePickerRef"
        v-model="inputVal"
        :type="type"
        :format="type==='datetime'?'yyyy-MM-dd HH:mm':format"
        :value-format="type==='datetime'?'yyyy-MM-dd HH:mm':format"
        :placeholder="placeholder"
        :range-separator="rangeSeparator"
        unlink-panels
        :clearable="clearable"
        :default-value="hasDeault?defaultDate:null"
        :disabled="disabled"
        size="small"
        start-placeholder="Date"
        end-placeholder="Date"
        :picker-options="pickerOptions"
        @change="handlerChange"
        @input.native="inputValue"
      />
    </section>
    <section class="show-vant">
      <van-field
        readonly
        clickable
        :rules="rules"
        class="v-input"
        :label="label"
        :value="text"
        :disabled="disabled"
        :name="name"
        :class="{
          'active': active
        }"
        @click="handlerClick">
        <template #input>
          <div class="width-fill flex-center-center">
            {{ text }}
          </div>
        </template>
        <template slot="right-icon">
          <font-awesome-icon
            class="icon-right"
            icon="calendar-alt"
          ></font-awesome-icon>
        </template>
      </van-field>
      <van-calendar
        v-if="isPicker"
        v-model="show"
        :type="vanType"
        :min-date="minDate"
        :max-date="maxDate"
        :show-subtitle="false"
        :show-confirm="true"
        :default-date="currentDate"
        color="#076081"
        @confirm="onConfirm"
      />
      <van-popup
        v-else
        v-model="show"
        position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          :type="type=='month'?'year-month':type"
          :min-date="minDate"
          :max-date="maxDate"
          :readonly="disabled"
          @cancel="show = false"
          @confirm="onConfirm" />
      </van-popup>
    </section>
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  name: 'VDatePicker',
  props: {
    value: {
      type: [String,Number,Array],
      default: ''
    },
    isTime:{
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    pickerOptions: {
      type: Object,
      default: () => ({})
    },
    // vant
    // 表單校驗定位用
    name: {
      type: String,
      default: ''
    },
    rangeSeparator: {
      type: String,
      default: 'to'
    },
    label: {
      type: [String],
      default: ''
    },
    // vant 表單校驗與 element 略有不同
    rules: {
      type: Array,
      default: () => ([])
    },
    active: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: [Object, Date],
      default: () => (new Date('1800-01-01'))
    },
    maxDate: {
      type: [Date],
      default: () => (new Date(dayjs().add(10, 'year')))
    },
    type: {
      type: String,
      default: 'date'
    },
    placeholder:{
      type: String,
      default:'Date'
    },
    isPicker:{
      type:Boolean,
      default:false
    },
    hasDeault:{
      type:Boolean,
      default:false
    },
    format:{
      type:String,
      default:'yyyy-MM-dd'
    }
  },
  data() {
    return {
      inputVal: '',
      text: '',
      show: false,
      currentDate: new Date(),
      isDefault:false,
      defaultDate:'1980-01-01'
    }
  },
  computed:{
    vanType(){
      return this.type ==='daterange'?'range':'single'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        // 後端接口可能會傳 null
        if(val === null && this.type === 'daterange') {
          this.inputVal = []
        }
        if(this.checkValue(val) || this.checkValue(this.inputVal)) {
          this.inputVal = val
        }
        // vant
        if (val) {
          if(this.type === 'daterange')
          {
            let [start, end] = val
            start = dayjs(start).format('YYYY-MM-DD')
            end = dayjs(end).format('YYYY-MM-DD')
            this.text = start+' to ' + end
          }
          else if(this.type==='month'){
            this.text = dayjs(val).format('YYYY-MM')
          }
          else{
            if(this.type==='datetime')
              this.text = dayjs(val).format('YYYY-MM-DD HH:mm')
            else this.text = dayjs(val).format('YYYY-MM-DD')
          }
          this.currentDate = new Date(val)
        }
        else {
          this.text = ''
          this.currentDate = new Date()
        }
      }
    },
    inputVal: {
      // immediate: true,
      // deep: true,
      handler(val, old) {
        // console.log('val--input==val',val)
        // console.log('papaspasd',val,old)
        // this.$emit('input', val)
        // this.$emit('change', val)
        if(val&&this.isDefault){
          this.isDefault = false
          this.inputVal = dayjs(val).format('YYYY-MM-DD 23:59')
        }
      }
    },
    isTime:{
      immediate:true,
      handler(val){
        if(val){
          this.isDefault = true
        }
      }
    }
  },
  methods: {
    inputValue(dom) {
      // console.log('val--input==dom',dom)
      // let value = dom.target.value
      // let str = value.replace(/[^\d]/g, '')
      // let len = str.length
      // let result = ''
      // console.log(dom)
      // if (len >= 8) {
      //   console.log(dom)
      //   result = `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6, len)}`
      //   this.inputVal = result
      //   this.$refs['datePickerRef'].userInput = result
      // }

    },
    handlerChange(dateArr) {
      this.$emit('input', dateArr)
      this.$emit('change', dateArr)
    },
    checkValue(value) {
      if(value === '' || value === null) {
        return false
      }
      return true
    },
    // vant
    onCancel() {
      this.show = false
    },
    handlerClick() {
      if(this.disabled) return
      this.show = true
    },
    onConfirm(value) {

      if(this.type=='daterange'){
        let [start, end] = value
        start = dayjs(start).format('YYYY-MM-DD')
        end = dayjs(end).format('YYYY-MM-DD')
        this.text = start+' to ' + end
        this.$emit('input', [start,end])
        this.$emit('change', [start,end])
      }else
      {

        let day = dayjs(value).format('YYYY-MM-DD')
        if(this.type==='month')
          day = dayjs(value).format('YYYY-MM')
        if(this.type==='datetime')
          day = dayjs(value).format('YYYY-MM-DD HH:mm')
        this.text = day
        this.$emit('input', day)
        this.$emit('change', day)
      }
      this.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
.v-date-picker {
  width: 100%;
  .el-date-editor.el-input, .el-date-editor {
    width: 100%;
  }
  .el-date-editor {
    ::v-deep .el-range-separator{
      width: 30px;
    }
  }
  // vant
  .v-input.van-cell {
      margin-top: 20px;
      padding: 5px 10px;
  }
   .icon-calandar {
      padding-right: 0.5rem;
      // width: 40px;
      height: 32px;
    }
    .active {
      &.van-field.v-input {
        border: 1px solid $blue;
      }
      /deep/ .van-field__control {
        color: $blue;
      }
    }
    .van-field--disabled {
      .width-fill {
        color: #969799;
        padding-left: 10px;
      }
    }
  .width-fill {
    padding-left: 10px;
  }
}
.icon-right{
  margin-right: 10px;
  color: #076081;
}
</style>

