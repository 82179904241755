<template>
  <div
    :style="'display:' + display"
    class="tooltip-wrap">
    <el-tooltip
      :content="tipContent"
      :disabled="tipContent == '' || !tipContent || tipContent == undefined"
      :effect="tipEffect"
      :placement="tipPlacement"
    >
      <span v-show="showContent">{{showContent}}</span>
      <slot></slot>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'VTooltip', // 文字提示组件
  props: {
    // 展示内容
    showContent: {
      type: String,
      default: '',
    },
    // 提示文字
    tipContent: {},
    // 效果
    tipEffect: {
      type: String,
      default: 'dark',
    },
    // 提示文字的位置
    tipPlacement: {
      type: String,
      default: 'top',
    },
    //
    display: {
      type: String,
      default: 'inline-block',
    },
  },
}
</script>
