import Vue from 'vue'
import ConfirmBox from '@/components/confirm_box/index'

const ConfirmBoxObject = Vue.extend(ConfirmBox)

ConfirmBox.install = function(data) {
  if ( typeof data !== 'object' || typeof data === null ) {
    throw new Error('參數類型必須為對象')
  }
  let instance = new ConfirmBoxObject({
    data
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.show = true
  })

  return instance.confirm()
}

export default ConfirmBox