<template>
  <section>
    <div
      v-show="!showUpload"
      class="file-box theme-second-blue">
      <i
        v-if="!disabled"
        class="el-icon-circle-close theme-second-blue"
        @click="handlerDelete"></i>

      <div class="file-info theme-second-blue">
        <span
          class="theme-second-blue hand"
          @click="openFile(fileUrl)">
          <slot>{{ defaultName }}</slot>
        </span>
      </div>
      <div
        class="btn-box"
        :class="[edit? '': 'btn-min-width']">
        <!--        <v-button-->
        <!--          plain-->
        <!--          type="primary"-->
        <!--          @click="downloadFile">Download</v-button>-->
        <template v-if="edit">
          <!--          <v-button-->
          <!--            plain-->
          <!--            type="danger"-->
          <!--            @click="handlerDelete">Delete</v-button>-->
          <v-upload
            ref="upload"
            btnType="warning"
            :btnPlain="true"
            label="Edit"
            :otherData="otherData"
            :uploadData="uploadData"
            :direct="direct"
            :isLimit="false"
            @uploadSuccess="handlerUploadSuccess" />
        </template>
      </div>
    </div>
    <v-upload
      v-show="showUpload"
      ref="upload"
      class="mg-b-10"
      label="Upload"
      :otherData="otherData"
      :uploadData="uploadData"
      :direct="direct"
      :isLimit="false"
      @uploadSuccess="handlerUploadSuccess" />
  </section>

</template>
<script>
// import { deleteOrderFile, deleteOrderDriverFile } from '@/api/order'
export default {
  name: 'FileContainer',
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: Object,
      default: () => ({})
    },
    otherData: {
      type: Object,
      default: () => ({})
    },
    direct: {
      type: Number,
      default: 1 // 只上傳不入庫
    },
    uploadData: {
      type: Object,
      default: () => ({})
    },
    limit:{
      type: Number,
      default: 1 // 只上傳不入庫
    },
    multiple:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  computed: {
    defaultName() {
      if(!this.fileData || !this.fileData.url) {
        return 'No File'
      }
      return (this.fileData  && this.fileData.client_name)? this.fileData.client_name: 'document.pdf'
    },
    showUpload() {
      if(this.edit && (!this.fileData || !this.fileData.url)) {
        return true
      }
      return false
    },
    fileUrl(){
      return this.fileData.url||''
    }
  },
  methods: {
    handlerDelete() {
      // // 區分是否直接删除数据库
      // if(this.direct === 0) {
      //   // 判斷是否為記名司機
      //   const { type } = this.uploadData
      //   if(type === 'nm_drvr_licence_pic' || type === 'nm_drvr_id_type_pic') {
      //     this.network().deleteOrderDriverFile()
      //   }
      //   else {
      //     this.network().deleteOrderFile()
      //   }
      // }
      // else {
      this.$emit('delete', this.otherData)
      // this.$refs.upload.handleRemove()
      // }
    },
    downloadFile() {
      // if(this.fileData && this.fileData.url) {
      //   window.open(this.fileData.url, '_blank')
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     message: 'no file'
      //   })
      // }
    },
    handlerUploadSuccess(uploadRsult) {
      this.$emit('uploadSuccess', uploadRsult)
    },
    network() {
      return {
        deleteOrderFile: async () => {
          const { data } = await deleteOrderFile(this.uploadData)
          this.$emit('delete', this.otherData)
        },
        deleteOrderDriverFile: async () => {
          const { data } = await deleteOrderDriverFile({
            type: this.uploadData.type,
            order_id: this.uploadData.order_id,
            driver_num: this.uploadData.num
          })
          this.$emit('delete', this.otherData)
        }
      }
    },
    openFile(url){
      if(url)
        window.open(url,'_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
 .file-box {
   position: relative;
        display: flex;
        //justify-content: space-between;
        align-items: center;
        //margin-bottom: 15px;
        //padding: 5px 15px;
        //min-height: 65px;
        //border: 1px solid #dddfe7;
        //border-radius: 4px;
        //background: #fcfcfc;
        .file-info {
          //flex: 1;
          //max-width: 100px;
          // width: calc(100% - 100px);
          padding: 0 10px;
          color: #616266;
         span{
           font-weight: normal!important;
         }
          font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .btn-box {
          //display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          align-items: center;

          .el-button {
            margin-left: 0;
            margin-right: 10px;
          }
        }
        .btn-max-width {
           min-width: 270px;
        }
        .btn-min-width {
          min-width: 100px;
        }
      }
  .hand{
    cursor:pointer;
  }
</style>
