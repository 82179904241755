<template>
  <!-- 控制事件冒泡-->
  <el-button
    v-if="stop"
    ref="ref-button"
    :size="size"
    :type="type"
    :disabled="disabled"
    :plain="plain"
    @click.stop="handlerClick">
    <font-awesome-icon
      v-if="icon"
      :icon="[iconType, icon]" />
    <span class="btn-label">
      <slot></slot>
    </span>
  </el-button>
  <el-button
    v-else
    ref="ref-button"
    :size="size"
    :type="type"
    :disabled="disabled"
    :plain="plain"
    @click="handlerClick">
    <font-awesome-icon
      v-if="icon"
      :icon="[iconType, icon]" />
    <span class="btn-label">
      <slot></slot>
    </span>
  </el-button>
</template>
<script>
export default {
  name: 'VButton',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    stop: {
      // 控制事件是否冒泡上傳
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handlerClick() {
      this.$emit('click')
    },
    currentClick() {
      this.$refs['ref-button'].$el.click()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-button {
  .svg-inline--fa + .btn-label {
    margin-left: 5px;
  }
}
</style>