<template>
  <div
    class="v-pagination pagination-flex"
    align="center">
    <div class="flex">
      <el-button
        class="button-blue"
        icon="el-icon-caret-left"
        circle
        @click="handlerPre"></el-button>
      <div class="pagination-margin">
        <!--        :disabled="totalPage<=currentPage"-->
        <v-input
          v-model.number="currentPage"

          @change="handleCurrentChange"></v-input> /{{totalPage}}
      </div>
      <el-button
        class="button-blue"
        icon="el-icon-caret-right"
        circle
        @click="handlerNext"></el-button>
    </div>
    <div class="flex">
      <span class="per-page">Rows per page</span>
      <el-pagination
        :current-page="current"
        :layout="layout"
        :page-size="size"
        :page-sizes="pageSizes"
        :pager-count="pagerCount"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPagination',
  props: {
    current: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50, 100]
      },
    },
    // 最大页码按钮数
    pagerCount: {
      type: Number,
    },
    total: {
      type: Number,
    },
    layout: {
      type: String,
      default: 'sizes',
    },
  },
  data() {
    return {
      currentPage: 1,
      size:20
    }
  },
  computed:{
    totalPage(){
      return Math.ceil(this.total/this.size)==0?1:Math.ceil(this.total/this.size)
    }
  },
  watch: {
    current: {
      immediate: true,
      handler(val) {
        if(val !== this.currentPage) {
          this.currentPage = val
        }
      }
    },
    currentPage(val){
      this.handleCurrentChange(val)
    }
  },
  methods: {
    handlerPre(){
      this.currentPage=  this.currentPage==1?1:this.currentPage-1
    },
    handlerNext(){
      this.currentPage= this.currentPage>=this.totalPage?this.currentPage:this.currentPage+1
    },
    handleCurrentChange(val) {
      this.$emit('handle-current-change', val)
    },
    handleSizeChange(val) {
      this.size = val
      this.$emit('handle-size-change', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-pagination {
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .flex{
    align-items: center;
    .el-input{
      width: 50px;
      .el-input__inner{
      }
    }
    .pagination-margin{
      margin: 0 10px;
    }
    .per-page{
      font-size: 14px;
      color: #4A5568;
    }
  }
}
</style>
