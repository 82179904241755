<template>
  <el-checkbox
    v-model="checked"
    :disabled="disabled"
    @change="changeCheck">
    <slot></slot>
  </el-checkbox>
</template>
<script>
export default {
  name: 'VCheckbox',
  props: {
    value: {
      type: [Boolean, Number],
      default: false
    },
    disabled:{
      type:Boolean,
      default: false
    },
    isBoolean: {
      type: Boolean,
      default: false
    },
    selectNumber:{
      type: [Boolean, Number],
      default: 1
    }
  },
  data() {
    return {
      checked: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.checked = val==this.selectNumber
        this.$emit('input', Number(val))

      }
    },
  },
  methods: {
    changeCheck(val) {
      const result = this.isBoolean? val: Number(val)
      this.$emit('input', result)
      this.$emit('change', result)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-checkbox{
  /deep/.is-checked{
    .el-checkbox__inner{
      background: $primary!important;
      border-color:$primary;
    }
    /deep/.el-checkbox__label{

    }
  }
}
</style>
