import Vue from 'vue'
import MessageBox from '@/components/message_box'

const MessageBoxContainer = Vue.extend(MessageBox)

MessageBox.install = function(data = {}) {
  if ( typeof data !== 'object' || typeof data === null ) {
    throw new Error('MessageBox 參數類型必須為對象')
  }
  let instance = new MessageBoxContainer({
    data
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.show = true
  })
  return instance
}

export default MessageBox