import { render, staticRenderFns } from "./v-row-col.vue?vue&type=template&id=6732fb70&scoped=true&"
import script from "./v-row-col.vue?vue&type=script&lang=js&"
export * from "./v-row-col.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6732fb70",
  null
  
)

export default component.exports