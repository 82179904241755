<template>
  <div class="rb-title">
    <span>{{title}} <span
      v-if="litter"
      class="litter">{{litter}}</span></span>

  </div>
</template>

<script>
export default {
  name: 'v-title',
  props:{
    title: {
      type: [String, Number],
      default: ''
    },
    litter: {
      type: [String, Number],
      default: ''
    },
  }
}
</script>

<style scoped lang="scss">
  .rb-title{
    margin:  30px 0;
   span{
     color: #000;
     font-weight: bold;
     font-size: 24px;
     &:before{
       content:'';
       border-left: 6px solid $primary;
       margin-right: 10px;
     }
     &.litter{
       font-size: 18px;
       &:before{
         content:'';
         border-left: 0;
         margin-right: 10px;
       }
     }
   }
  }
</style>
