const SET_TRAVEL = 'SET_TRAVEL'

const state = {
  travelerNo: 0
}

const mutations = {
  [SET_TRAVEL]: (state, value) => {
    state.travelerNo = value
  }
}

const actions = {
  setTravelNo({ commit }, val) {
    commit(SET_TRAVEL, val)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}