<template>
  <el-dialog 
    :visible.sync="show"
    :show-close="false">
    <div class="content">
      {{ message }}
      <!-- 得根據設計圖彈窗整 -->
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'VMessageBox',
  data() {
    return {
      show: false,
      message: ''   //  可能某些情況需要數組
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
    text-align: center;
}
</style>