<template>
  <el-col
    :xs="same?sameNumber:xs"
    :sm="same?sameNumber:sm"
    :md="same?sameNumber:md"
    :lg="same?sameNumber:lg"
    :xl="same?sameNumber:xl"
  >
    <slot class="row-content"></slot>
  </el-col>
</template>

<script>
export default {
  name: 'v-row-col',
  props: {
    xs: {
      type: [String, Number],
      default: 24
    },
    sm: {
      type: [String, Number],
      default: 12
    },
    md: {
      type: [String, Number],
      default: 12
    },
    lg: {
      type: [String, Number],
      default: 12
    },
    xl: {
      type: [String, Number],
      default: 12
    },
    same:{
      type:Boolean,
      default:false
    },
    sameNumber:{
      type: [String, Number],
      default: 24
    }
  }
}
</script>

<style scoped>

</style>
