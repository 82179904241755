<template>
  <section>
    <slot v-if="lazyDom"></slot>
    <div
      v-else
      class="text-center blue icon-loading-size">
      <i class="el-icon-loading"></i>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    time: {
      type: [String, Number],
      default: 1000
    }
  },
  data() {
    return {
      lazyDom: false
    }
  },
  created() {
    setTimeout(() => {
      this.lazyDom = true
    }, Number(this.time))
  }
}
</script>
