import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from '@/jslib/i18n'
Vue.use(i18n)

import '@/assets/css/common.scss'
import '@/assets/css/screen.scss'

import '@/jslib/element-ui'
import '@/jslib/vant-ui'
import './jslib/components' // 自定義組件
import '@/mixins/global'
import './jslib/fontawesom' // 圖標庫

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
