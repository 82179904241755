import Vue from 'vue'
import VueI18n from 'vue-i18n'

import hkLang from '@/assets/lang/zh-hk'
import enLang from '@/assets/lang/en-us'

// element
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-TW'

// vant
import { Locale } from 'vant'
import enLocaleVant from 'vant/lib/locale/lang/en-US'
import zhLocaleVant from 'vant/lib/locale/lang/zh-HK'

const vantLocale = (lang) => {
  if(lang === 'zh-HK') {
    Locale.use('zh-HK', zhLocaleVant)
  }
  else {
    Locale.use('en-US', enLocaleVant)
  }
}

Vue.prototype.$vantLocale = vantLocale


const config = {
  'en-US': [enLocale, enLang],
  'zh-HK': [zhLocale, hkLang]
}

// 由於webpack打包後的文件作為閉包處理，取不到外部變量
window.Vue = Vue
// 根據本地緩存或瀏覽器默認語言進行多語言處理
let lang = localStorage.getItem('lang')
// let lang = 'en-US' // 暫時默認英文
if (!lang) {
  lang = 'en-US'
  // lang = navigator.language || navigator.userLanguage
  localStorage.setItem('lang', lang)
}

// Vue.prototype.$lang = lang

vantLocale(lang)

const i18n = new VueI18n({
  locale: lang,
  messages: Object.keys(config).reduce((result, item) => {
    result[item] = (langArr => {
      let obj = {}
      // eslint-disable-next-line
      langArr.forEach(item => obj = Object.assign(obj, item))
      return obj
    })(config[item])
    return result
  }, {})
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n