import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
// 僅用於存放全局數據/方法
Vue.mixin({
  filters: {

  },
  data() {
    return {

    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    }
  },
  watch: {

  },
  methods: {
    changeLanguage() {
      const lang = localStorage.getItem('lang') === 'en-US' ? 'zh-HK' : 'en-US'
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
      this.$vantLocale(lang)
    },
    scrollToValidDom(validList) {
      try {
        Object.keys(validList).some(key => {
          console.log(key)
          console.log(this.$refs[key], '-=-=-=')

          const refs = this.$refs[key]
          const dom = refs instanceof Array? this.$refs[key][0].$el: this.$refs[key].$el
          this.scrollToDom(dom)
          return true
        })
      } catch (e) {
        console.log(e)
      }
    },
    scrollToDom(dom) {
      try {
        if(dom) {
          const { top } = dom.getBoundingClientRect()
          const scrollY = document.getElementsByClassName('scroll-content')[0].scrollTop || window.scrollY
          const { offsetHeight } = dom
          const scrollTopResult = Number(top) + Number(scrollY) - offsetHeight - 150
          this.scrollTo(Number(scrollTopResult))
        }
        else {
          this.scrollTo(0)
        }
      } catch (e) {
        console.log(e)
      }
    },
    scrollTo(top) {
      try {
        // window.scrollTo({
        //   top,
        //   behavior: 'smooth'
        // })
        const scrollContainer = document.getElementsByClassName('scroll-content')[0]
        scrollContainer.scrollTo({
          top,
          behavior: 'smooth'
        })
      } catch (e) {
        console.log(e)
      }
    },
    errScrollToValidDom(val){
      this.scrollToValidDom(val)
      Object.keys(val).some(key => {
        this.$refs[key].$el.className = 'el-form-item is-error'
        this.$refs[key].error = val[key]
        this.setErrorData(null)
        // let data = this.$refs[key].$el.getElementsByClassName('el-form-item__content')[0]
        // var div = document.createElement('div')
        // div.className = 'el-form-item__error'
        // div.innerText =  val[key]
        // data.appendChild(div)
      })
    }
  }
})
