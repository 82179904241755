const SET_POLICY_SEARCH = 'SET_POLICY_SEARCH'

const state = {
  search:null,
}


const mutations = {
  [SET_POLICY_SEARCH]: (state, value) => {
    state.search= value
  },
}


const actions = {
  setSearch({ commit },val){
    commit(SET_POLICY_SEARCH, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}