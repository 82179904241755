const SET_ClASS = 'SET_ClASS'

const state = {
  getClassShow: 'hidden-sm-and-down-slide',
}

const mutations = {
  [SET_ClASS]: (state, value) => {
    state.getClassShow = value
  }
}

const actions = {
  setClass({ commit }, val) {
    commit(SET_ClASS, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}