<template>
  <div class="header-title">
    <h1>
      {{title}}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'VHeader',
  props:{
    title:{
      type:String,
      default:''
    }
  }
}
</script>

<style scoped lang="scss">
.header-title{
  color: #000000;
  font-weight: 700;
  width: 100%;
  //padding: 10px 0;
  span{
    font-size: 16px;
  }
  h1{
    color: #000;
    padding:  0 0 20px 0;
    font-size:36px ;
    /*background: #f8f8f8;*/
  }
}
</style>
