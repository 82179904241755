import Vue from 'vue'
import 'vant/lib/index.css'

import {
  Field,
  DatetimePicker,
  Popup,
  Picker,
  Calendar
} from 'vant'

[
  Field,
  DatetimePicker,
  Popup,
  Picker,
  Calendar
].some(control => {
  Vue.use(control)
})