<template>
  <div
    :class="{
      'v-upload-container': card,
      'text-right__document':isShowDetail
    }">
    <div
      :class="{
        'upload-container': card,
      }">
      <el-upload
        ref="upload"
        action
        :multiple="multiple"
        :drag="drag"
        :on-remove="handleRemove"
        :disabled="disabled"
        :show-file-list="false"
        :limit="isLimit?limit:0"
        :http-request="customUpload"
        :file-list="fileList"
        :data="uploadData"
      >
        <template v-if="card">
          <div class="upload-card">
            <template v-if="localData.thumb_url">
              <img
                v-if="isPdf"
                class="img-pdf"
                src="@/assets/images/icon/pdf.png"
                @click.stop="handlerView"
              />
              <img
                v-if="localData.thumb_url"
                :src="localData.thumb_url"
                alt=""
                @click.stop="handlerView"/>
            </template>
            <div
              v-else
              class="add-icon">
              <div v-if="isLoading&&hasLoading">
                <span
                  class="text-center blue icon-loading-size">
                  <i class="el-icon-loading"></i>
                </span>
              </div>
              <v-button
                v-else
                ref="upload-button"
                :disabled="disabled"
                :plain="btnPlain"
                class="button-theme"
                :type="btnType">Upload</v-button>
            </div>
          </div>
        </template>
        <template v-else>
          <v-button
            ref="upload-button"
            :disabled="disabled"
            :plain="btnPlain"
            class="button-theme"
            :type="btnType">{{ label }}</v-button>
        </template>
        <slot></slot>
      </el-upload>
    </div>

    <!--    <div-->
    <!--      v-if="card && localData.url && !isBlog && !hideBtn&&!isShowDetail"-->
    <!--      class="upload-btn-container">-->
    <!--      <div-->
    <!--        class="upload-btn-box"-->
    <!--        @click.stop="handlerView"-->
    <!--      >-->
    <!--        <img-->
    <!--          src="@/assets/images/icon/calander-blue.png"-->
    <!--          alt="view" />-->
    <!--      </div>-->
    <!--      <div-->
    <!--        v-if="!disabled"-->
    <!--        class="upload-btn-box"-->
    <!--        @click.stop="handlerDelete">-->
    <!--        <img-->
    <!--          src="@/assets/images/icon/calander-blue.png"-->
    <!--          alt="delete" />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import {uploadOrderFile} from '@/api/order'

export default {
  name: 'VUpload',
  props: {
    otherData: {
      type: Object,
      default: () => ({})
    },
    uploadData: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    drag: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Upload file'
    },
    card: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: Object,
      default: () => ({})
    },
    btnType: {
      type: String,
      default: 'primary'
    },
    btnPlain: {
      type: Boolean,
      default: false
    },
    direct: {
      type: Number,
      default: 1 // 只上傳不入庫
    },
    notUpload: {
      type: Boolean,
      default: false
    },
    hasLoading: {
      type: Boolean,
      default: false
    },
    limit:{
      type: Number,
      default: 1
    },
    multiple:{
      type: Boolean,
      default: true
    },
    isLimit:{
      type: Boolean,
      default: true
    },
    isShowDetail:{
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      localData: {
        thumb_url: ''
      },
      fileList:[],
      isLoading:false
    }
  },
  computed: {
    // src_url() {
    //   return this.isLocal ? this.localData.local_url : this.localData.thumb_url
    // },
    isPdf(){
      let {client_name,path} = this.fileData
      if(String(client_name).toLowerCase().indexOf('.pdf') != -1||String(path).toLowerCase().indexOf('.pdf') != -1) {
        return true
      }
      return false
    }
  },
  watch: {
    fileData: {
      deep: true,
      immediate: true,
      handler(val) {
        let {url=''} = val||''
        // if(url)
        this.localData =val
        this.localData.thumb_url = url||''
      }
    }
  },
  methods: {
    handleBigView(){
      this.$emit('view')
    },
    handlerView() {
      // const windowWidth = 720 // 新窗口的宽度
      // const screenWidth = window.screen.width // 屏幕的宽度
      // const windowLeft = screenWidth - windowWidth // 窗口的左侧位置
      //
      // window.open(this.localData.url, '_blank', `width=${windowWidth}, left=${windowLeft}`)
      // window.open(this.localData.url, '_blank')
      const windowWidth = 720 // 新窗口的宽度
      const screenWidth = window.screen.width // 屏幕的宽度
      const windowLeft = screenWidth - windowWidth // 窗口的左侧位置

      const newWindow = window.open(this.localData.url, '_blank', `width=${windowWidth}, left=${windowLeft}`)


      newWindow.addEventListener('DOMContentLoaded', function() {
        const styleTag = newWindow.document.createElement('style')
        styleTag.innerHTML =
          `
          body {
              display: flex;
              justify-content: flex-end;
            }
           `

        newWindow.document.head.appendChild(styleTag)
      })
    },
    clearFiles(){
      this.fileList.splice(0,1)
    },
    handlerDelete() {
      this.$emit('delete', {
        otherData: this.otherData
      })
    },
    currentClick() {
      this.$refs['upload-button'].currentClick()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    // 自定義上傳
    customUpload(file) {
      this.isLoading  = true
      let params = new FormData()
      params.append('file', file.file)
      if(this.hasLoading)
        params.append('hideLoading', false)
      // 直接上傳固定參數
      params.append('direct', this.direct) // 默認為 1
      if(this.direct == 0) {
        Object.keys(this.uploadData).forEach(key => {
          params.append(key, this.uploadData[key])
        })
      }
      // 圖片預覽鏈接
      let result = {
        url: URL.createObjectURL(file.file),
        isImage: file.file.type.indexOf('image') !== -1
      }
      // this.localData.thumb_url= result.url
      if(this.notUpload) {
        this.$emit('uploadForm', {
          params,
          result,
          otherData: this.otherData
        })
        this.isLoading = false
      }
      else {
        this.network().uploadOrderFile(params, result)
      }

    //   console.log(result, '====')
    //   if(this.orderUpload) {
    //     this.network().uploadOrderFile(params, result)
    //   } else {
    //     this.$emit('upload', params, this.otherData, result)
    //   }
    },
    network() {
      return {
        uploadOrderFile: async (params, result) => {
          const { data } = await uploadOrderFile(params,this.hasLoading).catch((err)=>{
            this.isLoading  =false
          })
          const { url, isImage } = result
          // if(isImage) {
          //   this.localData.thumb_url = url
          // }
          const dataResult = {
            ...data,
            path: data.path,
            thumb_url: isImage? url: data.thumb_url // 使用本地圖片，避免不能即使獲取到遠程圖片問題
          }
          if(this.limit>1)
            this.fileList.push(dataResult)
          else{
            this.fileList = []
          }
          this.$emit('uploadSuccess', {
            data: dataResult,
            result,
            otherData: this.otherData
          })
          this.isLoading  =false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-card {
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 345px;
  height: 200px;
  border: 1px solid #CBD5E0;
  border-radius: 4px;
  @media screen and  (max-width:1422px){
    width: 200px;
    height: 134.55px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    z-index: 3;
  }
  .add-icon {
    width: 100%;
    height: 100%;
    display: contents;
  //  &::before {
  //  z-index: -1;
  //  position: absolute;
  //  content: '';
  //  top: 50%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //  width: 50px;
  //  height: 1px;
  //  background: #979797;
  //}
  //&::after {
  //  z-index: -1;
  //  position: absolute;
  //  content: '';
  //  top: 50%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //  width: 1px;
  //  height: 50px;
  //  background: #979797;
  //}
  }
  .upload-btn-box {
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 0px;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    background: #012a63;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
    }
  }
  &.noBorder{
    border: none;
    height: 100%;
  }
}
.delete-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
}

.width-full{
  width: 100% !important;
  /deep/.el-upload{
    width: 100%;
    height: 100%;
  }
  .upload-card{
    width: 100%
  }
}
/deep/.el-upload{
  display: contents;
}
/deep/ .button-theme{
  .btn-label{
    color: #fff;
  }
}
</style>
