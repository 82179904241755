
const SET_STATEMENT= 'SET_STATEMENT'
const SET_STATEMENT_TAB= 'SET_STATEMENT_TAB'
const SET_STATEMENT_DATA= 'SET_STATEMENT_DATA'

const state = {
  statementData: null,
  statementTab:null,
  statementParams:null
}

const mutations = {
  [SET_STATEMENT]: (state, value) => {
    state.statementData= value
  },
  [SET_STATEMENT_TAB]: (state, value) => {
    state.statementTab= value
  },
  [SET_STATEMENT_DATA]: (state, value) => {
    state.statementParams= value
  },
}


const actions = {
  setStatement({ commit }, val) {
    commit(SET_STATEMENT, val)
  },
  setStatementTab({ commit }, val) {
    commit(SET_STATEMENT_TAB, val)
  },
  setStatementParams({ commit }, val) {
    commit(SET_STATEMENT_DATA, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
