<template>
  <div class="v-date-picker">
    <!--    <section class="show-element">-->
    <el-date-picker
      ref="datePickerRef"
      v-model="inputVal"
      :type="type"
      :format="format"
      :value-format="format"
      placeholder="Date"
      start-placeholder="Date"
      end-placeholder="Date"
      :range-separator="rangeSeparator"
      unlink-panels
      :clearable="clearable"
      :disabled="disabled"
      size="small"
      :picker-options="pickerOptions"
      @change="handlerChange"
      @input.native="inputValue"
    />
    <!--    </section>-->
    <!--    <section class="show-vant">-->
    <!--      <van-field-->
    <!--        readonly-->
    <!--        clickable-->
    <!--        :rules="rules"-->
    <!--        class="v-input"-->
    <!--        :label="label"-->
    <!--        :value="text"-->
    <!--        :disabled="disabled"-->
    <!--        :name="name"-->
    <!--        :class="{-->
    <!--          'active': active-->
    <!--        }"-->
    <!--        @click="handlerClick">-->
    <!--        <template #input>-->
    <!--          <div class="width-fill flex-center-center">-->
    <!--            {{ text }}-->
    <!--          </div>-->
    <!--        </template>-->
    <!--        <template slot="right-icon">-->
    <!--          <font-awesome-icon-->
    <!--            class="icon-right"-->
    <!--            icon="calendar-alt"-->
    <!--          ></font-awesome-icon>-->
    <!--        </template>-->
    <!--      </van-field>-->
    <!--      <van-calendar-->
    <!--        v-if="isPicker"-->
    <!--        v-model="show"-->
    <!--        :type="vanType"-->
    <!--        :min-date="minDate"-->
    <!--        :max-date="maxDate"-->
    <!--        :show-subtitle="false"-->
    <!--        :show-confirm="true"-->
    <!--        :default-date="currentDate"-->
    <!--        color="#076081"-->
    <!--        @confirm="onConfirm"-->
    <!--      />-->
    <!--      <van-popup-->
    <!--        v-else-->
    <!--        v-model="show"-->
    <!--        position="bottom">-->
    <!--        <van-datetime-picker-->
    <!--          v-model="currentDate"-->
    <!--          :type="type=='month'?'year-month':type"-->
    <!--          :min-date="minDate"-->
    <!--          :max-date="maxDate"-->
    <!--          :readonly="disabled"-->
    <!--          @cancel="show = false"-->
    <!--          @confirm="onConfirm" />-->
    <!--      </van-popup>-->
    <!--    </section>-->
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  name: 'VMonthPicker',
  props: {
    value: {
      type: [String,Number,Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    pickerOptions: {
      type: Object,
      default: () => ({})
    },
    // vant
    // 表單校驗定位用
    name: {
      type: String,
      default: ''
    },
    rangeSeparator: {
      type: String,
      default: '-'
    },
    label: {
      type: [String],
      default: ''
    },
    // vant 表單校驗與 element 略有不同
    rules: {
      type: Array,
      default: () => ([])
    },
    active: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: [Object, Date],
      default: () => (new Date('1800-01-01'))
    },
    maxDate: {
      type: [Date],
      default: () => (new Date(dayjs().add(10, 'year')))
    },
    type: {
      type: String,
      default: 'monthrange'
    },
    placeholder:{
      type: String,
      default:'Date'
    },
    isPicker:{
      type:Boolean,
      default:false
    },
    format:{
      type:String,
      default:'yyyy-MM'
    }
  },
  data() {
    return {
      inputVal: '',
      text: '',
      show: false,
      currentDate: new Date()
    }
  },
  computed:{
    vanType(){
      return this.type ==='daterange'?'range':'single'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        // 後端接口可能會傳 null
        if(val === null && this.type === 'monthrange') {
          this.inputVal = []
        }
        if(this.checkValue(val) || this.checkValue(this.inputVal)) {
          this.inputVal = val
        }
        // vant
        if (val) {
          if(this.type === 'monthrange')
          {
            let [start, end] = val
            start = dayjs(start).format('YYYY-MM')
            end = dayjs(end).format('YYYY-MM')
            this.text = start+' to ' + end
          }
          else if(this.type==='month'){
            this.text = dayjs(val).format('YYYY-MM')
          }
          this.currentDate = new Date(val)
        }
        else {
          this.text = ''
          this.currentDate = new Date()
        }
      }
    },
    inputVal: {
      // immediate: true,
      // deep: true,
      handler(val, old) {
        // console.log('papaspasd',val,old)
        // this.$emit('input', val)
        // this.$emit('change', val)
      }
    }
  },
  methods: {
    inputValue(dom) {
      let value = dom.target.value
      let str = value.replace(/[^\d]/g, '')
      let len = str.length
      let result = ''
      console.log(dom)
      if (len >= 8) {
        console.log(dom)
        result = `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6, len)}`
        this.inputVal = result
        this.$refs['datePickerRef'].userInput = result
      }

    },
    handlerChange(dateArr) {
      this.$emit('input', dateArr)
      this.$emit('change', dateArr)
    },
    checkValue(value) {
      if(value === '' || value === null) {
        return false
      }
      return true
    },
    // vant
    onCancel() {
      this.show = false
    },
    handlerClick() {
      if(this.disabled) return
      this.show = true
    },
    onConfirm(value) {

      if(this.type=='monthrange'){
        let [start, end] = value
        start = dayjs(start).format('YYYY-MM')
        end = dayjs(end).format('YYYY-MM')
        this.text = start+' to ' + end
        this.$emit('input', [start,end])
        this.$emit('change', [start,end])
      }else
      {

        let day = dayjs(value).format('YYYY-MM-DD')
        if(this.type==='month')
          day = dayjs(value).format('YYYY-MM')
        this.text = day
        this.$emit('input', day)
        this.$emit('change', day)
      }
      this.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .v-date-picker {
    width: 100%;
    .el-date-editor.el-input, .el-date-editor {
      width: 100%;
    }
    .el-date-editor {
      ::v-deep .el-range-separator{
        width: 30px;
      }
    }
    // vant
    .v-input.van-cell {
      margin-top: 20px;
      padding: 5px 10px;
    }
    .icon-calandar {
      padding-right: 0.5rem;
      // width: 40px;
      height: 32px;
    }
    .active {
      &.van-field.v-input {
        border: 1px solid $blue;
      }
      /deep/ .van-field__control {
        color: $blue;
      }
    }
    .van-field--disabled {
      .width-fill {
        color: #969799;
        padding-left: 10px;
      }
    }
    .width-fill {
      padding-left: 10px;
    }
  }
  .icon-right{
    margin-right: 10px;
    color: #076081;
  }
</style>

