export default [
  {
    path: '/quotation/createQuoteHome',
    name: 'createQuoteHome',
    component: () => import('@/views/quotation/createQuoteHome'),
    meta: {
      keepAlive: false,
      title:'Create Quotation'
    }
  },
  {
    path: '/quotation/create/motor',
    name: 'motor',
    component: () => import('@/views/quotation/motor'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/contractors',
    name: 'contractors',
    component: () => import('@/views/quotation/contractors'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/travel',
    name: 'travelInsurance',
    component: () => import('@/views/quotation/travelInsurance'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/home',
    name: 'homeInsurance',
    component: () => import('@/views/quotation/homeInsurance'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/employee',
    name: 'employeeInsurance',
    component: () => import('@/views/quotation/employeeInsurance'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/business',
    name: 'businessInsurance',
    component: () => import('@/views/quotation/businessInsurance'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/domestic',
    name: 'domesticInsurance',
    component: () => import('@/views/quotation/domesticInsurance'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/construction',
    name: 'constructionInsurance',
    component: () => import('@/views/quotation/constructionInsurance'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path:'/quotation/create/other',
    name: 'otherInsurance',
    component: () => import('@/views/quotation/otherInsurance'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/info',
    name: 'info',
    component: () => import('@/views/quotation/info'),
    meta: {
      keepAlive: false,
      back:true
    }
  },
  {
    path: '/quotation/create/policy',
    name: 'policyCreated',
    component: () => import('@/views/quotation/policyCreated'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/quotation/premium/info',
    name: 'premiumInfo',
    component: () => import('@/views/quotation/premiumInfo'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/update/payment',
    name: 'updatePayment',
    component: () => import('@/views/quotation/updatePayment'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/quotation/endorsement',
    name: 'endorsementQuotation',
    component: () => import('@/views/quotation/endorsementQuotation'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/quotation/cancel',
    name: 'cancelQuotation',
    component: () => import('@/views/quotation/cancelQuotation'),
    meta: {
      keepAlive: false,
    }
  },
]
