<template>
  <v-dialog
    v-model="show"
    width="460px">
    <div class="dialog-container">
      <h3 v-if="title">{{ title }}</h3>
      <p class="break-all">
        {{ message }}
      </p>
      <div class="dialog-footer">
        <div v-if="isCancel">
          <v-button @click="onCancel">{{ cancelBtn }}</v-button>
        </div>
        <div>
          <v-button
            class="button-theme"
            :class="classButton?'classButton':''"
            @click="onConfirm">{{ confirmBtn }}</v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmBox',
  data() {
    return {
      show: false,
      title: '',
      message: '',
      cancelBtn: 'Cancel',
      confirmBtn: 'Confirm',
      promiseStatus: null,
      isCancel:true,
      classButton:false
    }
  },
  methods: {
    confirm() {
      return new Promise((resolve, reject) => {
        this.promiseStatus = {
          resolve,
          reject
        }
      })
    },
    onCancel() {
      this.show = false
      this.promiseStatus.reject()
    },
    onConfirm() {
      this.show = false
      this.promiseStatus.resolve()
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-container {
      padding: 30px;
      color: black;
      word-wrap: break-word;
      h3 {
          padding-bottom: 20px;
          font-size: 18px;
          line-height: 27px;
          font-weight: 700;
      }
      p {
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        word-break: break-word;
      }
      .dialog-footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 15px;
          .el-button {
              margin-left: 20px;
          }
      }
  .classButton{
    background:rgb(211,76,70)!important;
    color: #fff!important;
  }
    }
</style>
